import logo from './res/logo.png';
import saveAndReceive from './res/save_receive.png';
import investments from './res/investments.png';
import cashflow from './res/cashflow.png';
import howitworks from './res/howitworks.png';
import appstore from './res/app-store.png';
import playstore from './res/play-store.png';
import headerImage from './res/header_image.png';
import './App.css';

function App() {
    return (
        <main className="App">
            <header className="App-header" style={{backgroundImage: `url(${headerImage})`}}>
                <section>
                    <div>
                        <img src={logo} className={'App-logo'} alt={'Finapt Logo'}
                             style={{width: 141, height: 'auto'}}/>
                    </div>
                    <div className={"Page-links"}>
                        <a href={"#features"}>Features</a>
                        <a href={"#howitworks"}>How it works</a>
                        <a href={"https://form.jotform.com/242925127402350"}>Request access</a>
                    </div>
                    <div className={"Page-links"}>
                        <a href={"#support"}>Support</a>
                        <a className={"Get-app"} href={"#getapp"}>Download</a>
                    </div>
                </section>

                <section>
                    <div>
                        <h1>
                            What is tracking and budgeting app without
                        </h1>
                        <h1 style={{color: '#00AC47'}}>[EXECUTION]?</h1>

                        <div style={{marginTop: 30}}>

                            <p className={"Header-detail"}>
                                Finapt is a Personal Financial Tool for managing your finances with flexible automation
                            </p>
                        </div>

                        <div style={{marginTop: 40}}>
                            <a className={"Get-app"} href={"#getapp"}>Download</a>
                        </div>
                    </div>

                </section>

                <section className={"Header-fill"}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="header-shape-fill" opacity="0.33"
                              d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                        <path className="header-shape-fill" opacity="0.66"
                              d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                        <path className="header-shape-fill"
                              d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg>
                </section>

                <section className={"Header-fill-2"}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="header-shape-fill" opacity="0.33"
                              d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                        <path className="header-shape-fill" opacity="0.66"
                              d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                        <path className="header-shape-fill"
                              d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg>
                </section>
            </header>

            <section className={"Sections"}>
                <section id={"features"}>
                    <h1>Features</h1>
                    <div className={"Feature"}>
                        <div className={"Feature-Description"}>
                            <div className="Features-icon">
                                <svg aria-hidden="true" className="e-font-icon-svg e-far-save" viewBox="0 0 448 512"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49
                                         0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0
                                         0-14.059-33.941zM272 80v80H144V80h128zm122 352H54a6 6 0 0 1-6-6V86a6 6 0 0 1
                                         6-6h42v104c0 13.255 10.745 24 24 24h176c13.255 0 24-10.745 24-24V83.882l78.243
                                         78.243a6 6 0 0 1 1.757 4.243V426a6 6 0 0 1-6 6zM224 232c-48.523 0-88 39.477-88
                                         88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 128c-22.056
                                         0-40-17.944-40-40s17.944-40 40-40 40 17.944 40 40-17.944 40-40 40z"></path>
                                </svg>
                            </div>
                            <h3>Save and receive money instantly</h3>
                            <p>Just like your conventional banks, we offer a
                                flexible wallet with an account number that you can use to receive money anytime.</p>
                        </div>
                        <div className={"Image-placeholder"}>
                            <img src={saveAndReceive} alt={"Save and Receive"}/>
                        </div>
                    </div>
                    <div className={"Feature"}>
                        <div className={"Image-placeholder left"}>
                            <img src={investments} alt={"Investments"}/>
                        </div>
                        <div className={"Feature-Description"}>
                            <div className="Features-icon">
                                <svg aria-hidden="true" className="e-font-icon-svg e-fas-money-check"
                                     viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0 448c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V128H0v320zm448-208c0-8.84 7.16-16 16-16h96c8.84 0 16 7.16 16 16v32c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-32zm0 120c0-4.42 3.58-8 8-8h112c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H456c-4.42 0-8-3.58-8-8v-16zM64 264c0-4.42 3.58-8 8-8h304c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8v-16zm0 96c0-4.42 3.58-8 8-8h176c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8v-16zM624 32H16C7.16 32 0 39.16 0 48v48h640V48c0-8.84-7.16-16-16-16z"></path>
                                </svg>
                            </div>
                            <h3>Automate recurring payments and investments</h3>
                            <p>
                                Having to always make the same payments every month is tiring, tell us how you want to
                                spend your monthly income and we will keep doing it for you forever. In the future, we
                                will be able to offer the best financial advice.
                            </p>
                        </div>
                        <div className={"Image-placeholder right"}>
                            <img src={investments} alt={"Investments"}/>
                        </div>
                    </div>
                    <div className={"Feature"}>
                        <div className={"Feature-Description"}>
                            <div className="Features-icon">
                                <svg aria-hidden="true" className="e-font-icon-svg e-fas-sign-out-alt"
                                     viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path>
                                </svg>
                            </div>
                            <h3>Track your cashflow</h3>
                            <p>
                                We provide users with a comprehensive overview of their spending habits, allowing them
                                to make informed decisions. We also send cashflow breakdowns to your email every month.
                            </p>
                        </div>
                        <div className={"Image-placeholder"}>
                            <img src={cashflow} alt={"Cashflow"}/>
                        </div>
                    </div>
                </section>

                <section id={"howitworks"}>
                    <h1>How it Works</h1>
                    <div className={"Feature"}>
                        <div className={"Image-placeholder"}>
                            <img src={howitworks} alt={"How it works"}/>
                        </div>
                        <div className={"Feature-Description"}>
                            <div
                                className="elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list">
                                <h1 style={{marginBottom: 20}}>Easy Steps:</h1>
                                <div className="elementor-widget-container">
                                    <ul className="elementor-icon-list-items">
                                        <li className="elementor-icon-list-item">
											<span className="elementor-icon-list-icon">
							<svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512"
                                 xmlns="http://www.w3.org/2000/svg"><path
                                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
                                            <span className="elementor-icon-list-text">Add money to your wallet</span>
                                        </li>
                                        <li className="elementor-icon-list-item">
											<span className="elementor-icon-list-icon">
							<svg aria-hidden="true" className="e-font-icon-svg e-fas-dot-circle" viewBox="0 0 512 512"
                                 xmlns="http://www.w3.org/2000/svg"><path
                                d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path></svg>						</span>
                                            <span className="elementor-icon-list-text">Create a plan with budget</span>
                                        </li>
                                        <li className="elementor-icon-list-item">
											<span className="elementor-icon-list-icon">
							<svg aria-hidden="true" className="e-font-icon-svg e-fas-dot-circle" viewBox="0 0 512 512"
                                 xmlns="http://www.w3.org/2000/svg"><path
                                d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path></svg>						</span>
                                            <span className="elementor-icon-list-text">Add plan mandates</span>
                                        </li>
                                        <li className="elementor-icon-list-item">
											<span className="elementor-icon-list-icon">
							<svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512"
                                 xmlns="http://www.w3.org/2000/svg"><path
                                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
                                            <span
                                                className="elementor-icon-list-text">Set execution time and frequency</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={"getapp"}>
                    <h1>Download Finapt</h1>
                    <h2 className={"about"}>Financial Assistant that helps to put your money where it is needed.</h2>
                    <div className={"links"}>
                        <a href={"#"}><img src={appstore} alt={"App Store"}/></a>
                        <a href={"#"}><img src={playstore} alt={"Play Store"}/></a>
                    </div>
                </section>

                <section id={"request-access"}>
                    <h1>Download Finapt</h1>
                    <h2 className={"about"}></h2>
                    <div className={"links"}>
                        <a href={"#"}><img src={appstore} alt={"App Store"}/></a>
                        <a href={"#"}><img src={playstore} alt={"Play Store"}/></a>
                    </div>
                </section>
            </section>

            <footer className="site-footer" id="colophon" itemType="https://schema.org/WPFooter" itemScope="itemscope">
                <div
                    className="site-primary-footer-wrap ast-builder-grid-row-container site-footer-focus-item ast-builder-grid-row-full ast-builder-grid-row-tablet-full ast-builder-grid-row-mobile-full ast-footer-row-stack ast-footer-row-tablet-stack ast-footer-row-mobile-stack">
                    <div className="ast-builder-grid-row-container-inner">
                        <div
                            className="ast-builder-footer-grid-columns site-primary-footer-inner-wrap ast-builder-grid-row">
                            <div className="site-footer-primary-section-1 site-footer-section site-footer-section-1">
                                <div className="ast-builder-layout-element ast-flex site-footer-focus-item">
                                    <div className="ast-footer-social-1-wrap ast-footer-social-wrap">
                                        <div
                                            className="footer-social-inner-wrap element-social-inner-wrap social-show-label-false ast-social-color-type-custom ast-social-stack-none ast-social-element-style-filled">
                                            <a href="https://x.com/withFinapt" aria-label="Twitter" target="_blank"
                                               rel="noopener noreferrer"
                                               className="ast-builder-social-element ast-inline-flex ast-twitter footer-social-item"><span
                                                className="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path
                                                d="M18.244 2.25H21.552L14.325 10.51L22.827 21.75H16.17L10.956 14.933L4.99 21.75H1.68L9.41 12.915L1.254 2.25H8.08L12.793 8.481L18.244 2.25ZM17.083 19.77H18.916L7.084 4.126H5.117L17.083 19.77Z"></path></svg></span></a>
                                            <a
                                                href="https://www.instagram.com/withfinapt/" aria-label="Instagram"
                                                target="_blank" rel="noopener noreferrer"
                                                className="ast-builder-social-element ast-inline-flex ast-instagram footer-social-item"><span
                                                className="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path
                                                d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"></path></svg></span>
                                            </a>
                                            <a
                                                href="https://www.facebook.com/withFinapt" aria-label="Facebook"
                                                target="_blank" rel="noopener noreferrer"
                                                className="ast-builder-social-element ast-inline-flex ast-facebook footer-social-item"><span
                                                className="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path
                                                d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path></svg></span>
                                            </a>
                                            <a
                                                href="https://www.linkedin.com/company/withfinapt" aria-label="Linkedin"
                                                target="_blank" rel="noopener noreferrer"
                                                className="ast-builder-social-element ast-inline-flex ast-linkedin footer-social-item"><span
                                                className="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path
                                                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path></svg></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <aside
                                    className="footer-widget-area widget-area site-footer-focus-item footer-widget-area-inner"
                                    data-section="sidebar-widgets-footer-widget-1" aria-label="Footer Widget 1">
                                    <section id="block-7" className="widget widget_block widget_text">
                                        <p className="has-text-align-center">Phone: <a href="tel:+2348038325215"
                                                                                       target="_blank"
                                                                                       rel="noreferrer noopener nofollow">+234-803-832-5215</a> |
                                            Email: <a href="mailto:withfinapt@gmail.com" target="_blank"
                                                      rel="noreferrer noopener">withfinapt@gmail.com</a></p>
                                    </section>
                                    <section id="block-9" className="widget widget_block">
                                        <ul className="wp-block-social-links is-layout-flex wp-block-social-links-is-layout-flex"></ul>
                                    </section>
                                </aside>
                                <div
                                    className="ast-builder-layout-element ast-flex site-footer-focus-item ast-footer-copyright">
                                <div className="ast-footer-copyright"><p>Copyright © 2024 Finapt</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
</main>
)}

export default App;
